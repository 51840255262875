import { FormText } from '../FormFields/FormText';
import { useAppSelector } from '../../store/hooks';
import { FormSelect } from '../FormFields/FormSelect';
import {
  DepartmentInterfase,
  ProfileInterfase,
  TaxIdentificationTypeInterface,
  UserAccountsInterfase,
} from '../../interfaces';
import { FormikErrors, FormikProps, FormikTouched } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { GeneralTypePhoneServices } from '../../services/GeneralServices';
import { GeneralTypeInterface } from '../../interfaces/Dto/GeneralTypeInterface';
import { ConfirmModalStatus } from '../Preshipment/ModalSuccess';
import { ModalLoading } from '../Atoms/ModalLoading';
import { FormSearch } from '../FormFields/FormSearch';
import {
  filterDepartament,
  filterGeneral,
  filterPieces,
  filterProfile,
} from '../../utils/filters';
import {
  DepartmentOption,
  GenericoOption,
  ProfileOption,
} from '../FormFields/FormSearchOptions';
import {
  getAllDepartament,
  getAllProfile,
  getAllUser,
  getExitsUser,
} from '../../services/UserServices';
import { UsersSearchHistoric } from './UsersSearch';
import { obtenerCodigoArea, obtenerNumero } from '../../utils/format';
import {
  ArrowPathIcon,
  CheckIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

export interface UserFormValues {
  userID?: string;
  accountID?: string;
  isValidatonLogin?: number;
  userLogin: string;
  userName: string;
  userLastName: string;
  userIdentificationTypeID?: TaxIdentificationTypeInterface;
  isValidatonIdenti?: number;
  userIdentificationNumber: string;
  profileID?: number;
  departamentID?: number;
  statusID?: number;
  userPhone: string;
  isValidatonEmail?: number;
  userEmail: string;
  codePhoneNumber?: string;
  accountSelected?: UserAccountsInterfase;
  reset?: boolean;
  id?: string;
  accountSiteID?: number;
}

interface UserFormField {
  user: UserFormValues;
}
interface UserFormProps<T> {
  formik: FormikProps<T & UserFormField>;
  loadingUser?: number;
}

export const UserForm = <T extends any>({
  formik,
  loadingUser,
}: UserFormProps<T>) => {
  const identificationTypes = useAppSelector(
    (state) => state.inmutable.taxIdentificationTypes
  );
  const user = useAppSelector((state) => state.user)!;
  const errors = formik.errors.user as FormikErrors<UserFormValues> | undefined;
  const touched = formik.touched.user as
    | FormikTouched<UserFormValues>
    | undefined;

  const [phonePreSearch, setPhonePreSearch] = useState('');
  const [selectUserAccount, setselectUserAccount] = useState<boolean>(false);
  const [phonePrefixList, setPhonePrefixList] = useState<string[]>([]);

  const [disabledIdentification, setDisabledIdentification] =
    useState<boolean>(false);
  let [loading, setLoading] = useState(false);
  let [loadingStatus, setLoadingStatus] = useState(
    ConfirmModalStatus.PROCESSING
  );
  let [loadingTitle, setLoadingTitle] = useState('Cargando...');

  const updatePhoneSearch = useCallback(() => {
    if (!!formik.values.user.codePhoneNumber) {
      setPhonePreSearch(formik.values.user.codePhoneNumber);
    } else {
      setPhonePreSearch('');
    }
  }, [formik.values.user.codePhoneNumber]);

  const [profileSearch, setProfileSearch] = useState('');
  const [profileList, setProfileList] = useState<ProfileInterfase[]>([]);
  const [departamentSearch, setDepartamentSearch] = useState('');
  const [departamentList, setDepartamentList] = useState<DepartmentInterfase[]>(
    []
  );

  useEffect(() => {
    const accountUserID = formik.values.user.id;
    if (accountUserID === undefined) return;

    const fetchShipments = async () => {
      setLoadingStatus(ConfirmModalStatus.PROCESSING);
      setLoadingTitle('Cargando...');
      setLoading(true);
      const accountID = formik.values.user.accountID ?? '' + user?.client?.id;
      const accountSiteID =
        '' + formik.values.user.accountSiteID ??
        '' + user?.client?.accountSiteId;

      const userAccountFind = await getAllUser(
        accountID,
        accountSiteID,
        accountUserID
      );

      var lisUserAccount = userAccountFind?.model as UserAccountsInterfase[];
      if (!userAccountFind?.didError) {
        let client = lisUserAccount[0];
        setselectUserAccount(true);
        setDisabledIdentification(true);
        setProfileSearch(
          profileList.find(
            (x) =>
              x.profileID?.toString() === (client.profileID?.toString() ?? 0)
          )?.profileName ?? ''
        );

        setDepartamentSearch(
          departamentList.find(
            (x) =>
              x.departamentID?.toString() === client.departamentID?.toString()
          )?.departamentName ?? ''
        );

        formik.setValues({
          ...formik.values,
          user: {
            ...formik.values.user,
            userID: client.userID,
            userLogin: client.userLogin?.trim() ?? '',
            userName: client.userName?.trim() ?? '',
            userLastName: client.userLastName?.trim() ?? '',
            userIdentificationTypeID: identificationTypes.find(
              (t) =>
                t.taxIdentificationTypeId === client?.userIdentificationTypeID
            )!,
            userIdentificationNumber:
              client.userIdentificationNumber?.trim() ?? '',
            profileID: client.profileID ?? 0,
            departamentID:
              client.departamentID !== 0 ? client.departamentID : undefined,
            statusID: client.statusID ?? undefined,
            codePhoneNumber: obtenerCodigoArea(client.userPhone?.trim()) ?? '',
            userPhone: obtenerNumero(client.userPhone?.trim()) ?? '',
            userEmail: client.userEmail?.trim() ?? '',
            accountSelected: client,
            reset: false,
          },
        });

        setLoadingStatus(ConfirmModalStatus.SUCCESS);
        setLoading(false);
      } else {
        setLoadingStatus(ConfirmModalStatus.ERROR);
        setLoadingTitle('Error: ' + userAccountFind?.errorMessage);
        setLoading(true);
      }
    };
    fetchShipments();
  }, [!!formik.values.user?.id, profileList, departamentList]);

  const getLocations = async () => {
    const phonetype = await GeneralTypePhoneServices('236');
    if (!!phonetype.didError || !phonetype.model) return;

    const emptyPhonePrefix = { value: 'FIJO', text: 'FIJO' };
    let phonetypeList = phonetype.model as unknown as GeneralTypeInterface[];
    phonetypeList.push(emptyPhonePrefix);

    setPhonePrefixList(
      (phonetypeList as unknown as GeneralTypeInterface[]).map(
        (phone) => phone.value
      )
    );
  };

  const getDepartament = async () => {
    const departament = await getAllDepartament();
    if (!!departament?.didError || !departament?.model) return;
    setDepartamentList(departament.model);
  };

  const getProfile = async () => {
    const profile = await getAllProfile();
    if (!!profile?.didError || !profile?.model) return;
    setProfileList(profile.model);
  };

  useEffect(() => {
    getLocations();
    getDepartament();
    getProfile();
  }, []);

  return (
    <div className="flex h-full flex-col gap-1">
      {formik.values.user.id === undefined && (
        <div>
          <UsersSearchHistoric
            title=""
            selected={formik.values.user.accountSelected}
            error={
              touched?.accountSelected && errors?.accountSelected
                ? errors?.accountSelected
                : undefined
            }
            placeholder="Buscar usuario..."
            onSelectClient={async (client) => {
              setselectUserAccount(true);
              setDisabledIdentification(true);

              setProfileSearch(
                profileList.find(
                  (x) =>
                    x.profileID?.toString() === client.profileID?.toString() ??
                    0
                )?.profileName ?? ''
              );

              setDepartamentSearch(
                departamentList.find(
                  (x) =>
                    x.departamentID?.toString() ===
                    client.departamentID?.toString()
                )?.departamentName ?? ''
              );

              formik.setValues({
                ...formik.values,
                user: {
                  ...formik.values.user,
                  userID: client.userID,
                  userLogin: client.userLogin?.trim() ?? '',
                  userName: client.userName?.trim() ?? '',
                  userLastName: client.userLastName?.trim() ?? '',
                  userIdentificationTypeID: identificationTypes.find(
                    (t) =>
                      t.taxIdentificationTypeId ===
                      client?.userIdentificationTypeID
                  )!,
                  userIdentificationNumber:
                    client.userIdentificationNumber?.trim() ?? '',
                  profileID: client.profileID ?? 0,
                  departamentID:
                    client.departamentID !== 0
                      ? client.departamentID
                      : undefined,
                  statusID: client.statusID ?? undefined,
                  codePhoneNumber:
                    obtenerCodigoArea(client.userPhone?.trim()) ?? '',
                  userPhone: obtenerNumero(client.userPhone?.trim()) ?? '',
                  userEmail: client.userEmail?.trim() ?? '',
                  accountSelected: client,
                  reset: false,
                },
              });
            }}
            openCreationModal={() => {
              formik.setValues({
                ...formik.values,
                user: {
                  userID: undefined,
                  userLogin: '',
                  userName: '',
                  userLastName: '',
                  userIdentificationTypeID: identificationTypes.find(
                    (t) => t.abbreviationName === 'V-'
                  )!,
                  userIdentificationNumber: '',
                  profileID: undefined,
                  departamentID: undefined,
                  statusID: undefined,
                  userPhone: '',
                  codePhoneNumber: '',
                  userEmail: '',
                  accountSelected: {} as UserAccountsInterfase,
                  reset: false,
                },
              });
              setselectUserAccount(true);
              setDisabledIdentification(false);
            }}
          />{' '}
          <hr className="my-6" />
        </div>
      )}

      {selectUserAccount === true ||
      (formik.values.user.userID !== undefined &&
        formik.values.user.userID !== '') ? (
        <div className="flex h-full flex-col gap-1 mt-1 pl-6">
          <h4 className="text-sm text-main-500 gap-2 font-bold mb-3 mt-1">
            Detalles del usuario:
          </h4>
          <div className="flex flex-row">
            <FormText
              id="user.userLogin"
              name="user.userLogin"
              label="Usuario"
              value={formik.values.user.userLogin}
              error={
                touched?.userLogin && errors?.userLogin
                  ? errors?.userLogin
                  : undefined
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="!h-8"
              labelClassname="!text-xs"
              labelContainerClassname="flex sm:!justify-end sm:w-32"
              containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
              style={{ maxWidth: '45rem' }}
            />
            <div className="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row ml-5">
              {loadingUser === 0 && (
                <ArrowPathIcon
                  className="w-5 ml-2 animate-spin"
                  style={{ color: '#7a7a7a' }}
                >
                  {' '}
                </ArrowPathIcon>
              )}

              {loadingUser === 1 && (
                <CheckIcon
                  className="w-5 ml-2"
                  style={{ color: '#166534' }}
                ></CheckIcon>
              )}

              {loadingUser === 2 && (
                <XMarkIcon className="w-5 ml-2 -mt-7 text-sm text-red-500"></XMarkIcon>
              )}
            </div>
          </div>

          <FormSelect
            autoComplete="off"
            id="user.userIdentificationTypeID"
            name="user.userIdentificationTypeID"
            label="Tipo de Documento"
            selected={formik.values.user.userIdentificationTypeID}
            options={identificationTypes}
            error={
              touched?.userIdentificationTypeID &&
              errors?.userIdentificationTypeID
                ? errors?.userIdentificationTypeID
                : undefined
            }
            onBlur={formik.handleBlur}
            className="!h-7"
            labelClassname="!text-xs"
            labelContainerClassname="flex sm:!justify-end sm:w-32"
            containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            style={{ maxWidth: '10rem' }}
            optionString={(option) => option.abbreviationName.slice(0, 1)}
            onSelectOption={(option) => {
              formik.setFieldValue('user.userIdentificationTypeID', option);
            }}
            disabled={disabledIdentification}
          />
          <FormText
            id="user.userIdentificationNumber"
            name="user.userIdentificationNumber"
            label="Nro. Documento"
            value={formik.values.user.userIdentificationNumber}
            error={
              touched?.userIdentificationNumber &&
              errors?.userIdentificationNumber
                ? errors?.userIdentificationNumber
                : undefined
            }
            onChange={(e) => {
              let re: RegExp;
              if (
                formik.values.user.userIdentificationTypeID
                  ?.abbreviationName !== 'P-'
              ) {
                // Only numbers
                re = /^[0-9\b]{0,10}$/;
              } else {
                // Only numbers and letters
                re = /^[a-zA-Z0-9\b]{0,10}$/;
              }

              if (e.target.value === '' || re.test(e.target.value)) {
                formik.handleChange(e);
              }
            }}
            onBlur={formik.handleBlur}
            className="!h-8"
            labelClassname="!text-xs"
            labelContainerClassname="flex sm:!justify-end sm:w-32"
            containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            style={{ maxWidth: '10rem' }}
            disabled={disabledIdentification}
          />
          <FormText
            id="user.userName"
            name="user.userName"
            label="Nombre"
            value={formik.values.user.userName}
            error={
              touched?.userName && errors?.userName
                ? errors?.userName
                : undefined
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="!h-8"
            labelClassname="!text-xs"
            labelContainerClassname="flex sm:!justify-end sm:w-32"
            containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            style={{ maxWidth: '45rem' }}
          />
          <FormText
            id="user.userLastName"
            name="user.userLastName"
            label="Apellido"
            value={formik.values.user.userLastName}
            error={
              touched?.userLastName && errors?.userLastName
                ? errors?.userLastName
                : undefined
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="!h-8"
            labelClassname="!text-xs"
            labelContainerClassname="flex sm:!justify-end sm:w-32"
            containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            style={{ maxWidth: '45rem' }}
          />

          <FormSearch
            id="user.codePhoneNumber"
            name="user.codePhoneNumber"
            label="Operadora"
            value={phonePreSearch}
            options={phonePrefixList}
            unselect={!!formik.values.user.codePhoneNumber}
            className="!h-8"
            labelClassname="!text-xs"
            labelContainerClassname="flex sm:!justify-end sm:w-32"
            containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            error={
              touched?.codePhoneNumber && errors?.codePhoneNumber
                ? errors?.codePhoneNumber
                : undefined
            }
            onBlur={formik.handleBlur}
            onChange={(e) => setPhonePreSearch(e.target.value)}
            onSelectOption={(option) =>
              formik.setFieldValue('user.codePhoneNumber', option)
            }
            onChangeFocus={(focus) => !focus && updatePhoneSearch()}
            style={{ maxWidth: '45rem' }}
            onFilter={filterGeneral}
            RenderOption={({ option }) => <GenericoOption text={option} />}
          />

          <FormText
            id="user.userPhone"
            name="user.userPhone"
            label="Teléfono"
            value={formik.values.user.userPhone}
            error={
              touched?.userPhone && errors?.userPhone
                ? errors?.userPhone
                : undefined
            }
            onChange={(e) => {
              let re: RegExp;
              re = /^[0-9\b]{0,10}$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                formik.handleChange(e);
              }
            }}
            maxLength={formik.values.user.userPhone !== 'FIJO' ? 7 : 10}
            onBlur={formik.handleBlur}
            className="!h-8"
            labelClassname="!text-xs"
            labelContainerClassname="flex sm:!justify-end sm:w-32"
            containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            style={{ maxWidth: '45rem' }}
          />

          <FormText
            id="user.userEmail"
            name="user.userEmail"
            label="Correo"
            value={formik.values.user.userEmail}
            error={
              touched?.userEmail && errors?.userEmail
                ? errors?.userEmail
                : undefined
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="!h-8"
            labelClassname="!text-xs"
            labelContainerClassname="flex sm:!justify-end sm:w-32"
            containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            style={{ maxWidth: '45rem' }}
          />

          <FormSearch
            id="user.departamentID"
            name="user.departamentID"
            label="Departamento"
            value={departamentSearch}
            options={departamentList}
            unselect={!!formik.values.user.departamentID}
            className="!h-8"
            labelClassname="!text-xs"
            labelContainerClassname="flex sm:!justify-end sm:w-32"
            containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            error={
              touched?.departamentID && errors?.departamentID
                ? errors?.departamentID
                : undefined
            }
            onBlur={formik.handleBlur}
            onChange={(e) => setDepartamentSearch(e.target.value)}
            onSelectOption={(option) => {
              setDepartamentSearch(option?.departamentName ?? '');
              formik.setFieldValue(`user.departamentID`, option?.departamentID);
            }}
            style={{ maxWidth: '45rem' }}
            onFilter={filterDepartament}
            RenderOption={({ option }) => <DepartmentOption {...option} />}
          />

          <FormSearch
            id="user.profileID"
            name="user.profileID"
            label="Perfiles"
            className="!h-8"
            labelClassname="!text-xs"
            labelContainerClassname="flex sm:!justify-end sm:w-32"
            containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            style={{ maxWidth: '45rem' }}
            value={profileSearch}
            options={profileList}
            unselect={!!formik.values.user.profileID}
            error={
              touched?.profileID && errors?.profileID
                ? errors?.profileID
                : undefined
            }
            onBlur={formik.handleBlur}
            onChange={(e) => setProfileSearch(e.target.value)}
            onSelectOption={(option) => {
              setProfileSearch(option?.profileName ?? '');
              formik.setFieldValue(`user.profileID`, option?.profileID);
            }}
            onFilter={filterProfile}
            RenderOption={({ option }) => <ProfileOption {...option} />}
          />

          <ModalLoading
            open={loading}
            title={loadingTitle}
            status={loadingStatus}
            setOpen={setLoading}
            onPrint={() => {}}
          />
        </div>
      ) : (
        <div className="red2" />
      )}
    </div>
  );
};
