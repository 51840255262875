import axios from 'axios';
import {
  AccountInterface,
  AccountPhoneInterface,
  AccountShipmentDto,
  AccountSiteInterfases,
  BusinessUnitInterface,
  DeliveryEnum,
  DestinationDto,
  DestinationInterface,
  FavAndHistoricalDestinationListInterface,
  Nullable,
  PaymentMethodAccountDto,
  RecurrentDestinationInterface,
  TaxIdentificationTypeInterface,
} from '../interfaces';
import { randomAccount } from '../utils/random';
import { ListResponse } from '../interfaces/Shipment/ListResponseDto';
import { NavigationItem } from '../components';
import { accountMapper, businessUnitMapper } from '../utils/mappers';
import { AuthenticationServices } from './AuthenticationServices';
const { REACT_APP_URL_PATH_API } = process.env;

export const getTaxIdentificationTypes = async (): Promise<
  TaxIdentificationTypeInterface[]
> => {
  //console.log(`[API CALL] GET Tax Identification Types`);
  return [
    {
      taxIdentificationTypeId: 1,
      taxIdentificationTypeCode: '001',
      taxIdentificationTypeName: 'Cedula Identidad',
      taxIdentificationTypeRegExp: '[V|E]{1-8}',
      countryId: 236,
      isJuridic: true,
      abbreviationName: 'V-',
    },
    {
      taxIdentificationTypeId: 2,
      taxIdentificationTypeCode: '002',
      taxIdentificationTypeName: 'Pasaporte',
      taxIdentificationTypeRegExp: '{1-9}',
      countryId: 236,
      isJuridic: true,
      abbreviationName: 'P-',
    },
    {
      taxIdentificationTypeId: 3,
      taxIdentificationTypeCode: '003',
      taxIdentificationTypeName: 'Gubernamental',
      taxIdentificationTypeRegExp: '[G]{1-8}',
      countryId: 236,
      isJuridic: false,
      abbreviationName: 'G-',
    },
    {
      taxIdentificationTypeId: 4,
      taxIdentificationTypeCode: '004',
      taxIdentificationTypeName: 'RIF',
      taxIdentificationTypeRegExp: '[J|G|V|E]-{1-8}-{0}',
      countryId: 236,
      isJuridic: false,
      abbreviationName: 'J-',
    },
    {
      taxIdentificationTypeId: 5,
      taxIdentificationTypeCode: '005',
      taxIdentificationTypeName: 'Extranjero',
      taxIdentificationTypeRegExp: '[E]{1-8}',
      countryId: 236,
      isJuridic: true,
      abbreviationName: 'E-',
    },
  ];
};

export const getAccounts = async (): Promise<AccountInterface[]> => {
  //console.log(`[API CALL] GET Accounts`);

  return new Array(Math.floor(Math.random() * 100) + 20)
    .fill(0)
    .map(() => randomAccount());
};

export const getAccount = async (
  accountId: string
): Promise<Nullable<AccountInterface>> => {
  //const uri = `${baseURLJsonServer}/accounts?id=${accountId}`;
  if (!accountId) {
    return null;
  }
  const token = await AuthenticationServices();
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

  const uri = `${REACT_APP_URL_PATH_API}/Account/GetAccount?accountId=${accountId}`;

  try {
    const response = await axios.get(uri);
    if (response.status === 200) {
      return accountMapper(response.data);
    }
  } catch (error) {}

  return null;
};

export const getFilteredAccounts = async (
  criteria: string
): Promise<Nullable<AccountInterface[]>> => {
  const token = await AuthenticationServices();
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

  const uri = `${REACT_APP_URL_PATH_API}/Account?idStr=${criteria}&maxRows=20&withAgreementAuths=true`;
  try {
    const token = await AuthenticationServices();
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    const response = await axios.get(uri);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {}

  return null;
};

export const getFavoriteAndHistoricalLocations = async (
  accountId: string,
  taxIdentificationTypes: TaxIdentificationTypeInterface[],
  buList: BusinessUnitInterface[],
  search?: string
): Promise<ListResponse<FavAndHistoricalDestinationListInterface>> => {
  const token = await AuthenticationServices();
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

  const uri = `${REACT_APP_URL_PATH_API}/GetFavoriteAndRecentDestinations?AccountID=${accountId}&SearchInput=${search}`;

  try {
    const token = await AuthenticationServices();
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    const response = await axios.get(uri);
    if (
      response.status === 200 &&
      !response.data.didError &&
      !!response.data.model
    ) {
      const favoriteDestination = response.data
        .model as RecurrentDestinationInterface[];

      const favorite = favoriteDestination
        .filter((fav) => fav.tipoAdr === 'Favorito')
        .map((destination: RecurrentDestinationInterface) =>
          mapToDestiInterface(destination, taxIdentificationTypes, buList)
        );
      const Sales = favoriteDestination
        .filter((fav) => fav.tipoAdr === 'Sales')
        .map((destination: RecurrentDestinationInterface) =>
          mapToDestiInterface(destination, taxIdentificationTypes, buList)
        );

      return {
        message: '',
        didError: false,
        errorMessage: '',
        model: {
          accountID: '',
          favoriteDestinations: favorite,
          historicalDestinations: Sales,
        },
      };
    } else if (response.status === 200) {
      return response.data;
    }

    return {
      model: null,
      didError: true,
      message: `Server status: ${response.status}`,
      errorMessage: 'Server error',
    };
  } catch (error) {
    return {
      model: null,
      didError: true,
      message: `Error: ${error}`,
      errorMessage: 'Application error',
    };
  }
};

// Method to map DestinationDto to DestinationInterface
export const mapToDestiInterface = (
  destination: RecurrentDestinationInterface,
  taxIdTypes: TaxIdentificationTypeInterface[],
  buList: BusinessUnitInterface[]
): DestinationInterface => {
  const taxIdType = taxIdTypes.find(
    (taxIdType) =>
      taxIdType.taxIdentificationTypeId ===
      destination.consigneeIdentificationType
  );

  let businessUnit: BusinessUnitInterface;

  if (destination.deliveryTypeID.toString() === DeliveryEnum.OFFICE) {
    businessUnit = buList.find((bu) => bu.buCode === destination.buCode)!;
  } else {
    businessUnit = {} as BusinessUnitInterface;
  }

  return {
    account: {
      id: destination.consigneeAccountID,
      //accountCode: destination.accountCode,
      taxIdentificationTypeID: destination.consigneeIdentificationType,
      identificationNumber: destination.consigneeIdentificationNumber,
      accountFullName: destination.consigneeFullName,
      taxIDentificationTypeCode: taxIdType?.taxIdentificationTypeCode ?? '',
      abbreviationName: taxIdType?.abbreviationName ?? '',
      listAccountPhone: destination.consigneePhone
        ? [
            {
              phoneID: '', // No disponible en RecurrentDestinationInterface
              accountId: destination.consigneeAccountID || '',
              countryId: '', // No disponible en RecurrentDestinationInterface
              phoneNumber: destination.consigneePhone || '',
              countryPhoneAccessCode: '', // No disponible en RecurrentDestinationInterface
              phoneTypeId: '', // No disponible en RecurrentDestinationInterface
            },
          ]
        : [],
      listAccountEmail: [],
      listAuthorizingAccount: [],
      creationDate: '',
      creationUser: '',
    },
    businessUnit: businessUnit ?? {},
    location: {
      name: destination.addressName,
      address: destination.addressLine1,
      reference: '',
      postalCode: destination.postalCode,
      coordinates: {
        lat: destination.latitude ?? 0,
        lng: destination.longitude ?? 0,
      },
    },
    store: {
      id:
        buList.find((x) => x.buCode === destination.buCode)?.buid.toString() ??
        '',
      name: destination.buAreaName,
      code: destination.buCode,
      location: {
        name: destination.addressName ?? '',
        address: destination.addressLine1 ?? '',
        reference: '',
        postalCode: destination.postalCode ?? '',
        coordinates: {
          lat: destination.latitude ?? 0,
          lng: destination.longitude ?? 0,
        },
      },
      polygonList: [],
    },
    isHomeDelivery:
      destination.deliveryTypeID.toString() === DeliveryEnum.DELIVERY,
  };
};

// Method to map DestinationDto to DestinationInterface
export const mapToDestinationInterface = (
  destination: DestinationDto,
  taxIdTypes: TaxIdentificationTypeInterface[]
): DestinationInterface => {
  const taxIdType = taxIdTypes.find(
    (taxIdType) =>
      taxIdType.taxIdentificationTypeId === destination.taxIdentificationTypeID
  );
  return {
    account: {
      id: destination.accountID,
      accountCode: destination.accountCode,
      taxIdentificationTypeID: destination.taxIdentificationTypeID,
      identificationNumber: destination.identificationNumber,
      accountFullName: destination.accountFullName,
      taxIDentificationTypeCode: taxIdType?.taxIdentificationTypeCode ?? '',
      abbreviationName: taxIdType?.abbreviationName ?? '',
      listAccountPhone: destination.accountPhoneList.map((phone) => ({
        phoneID: phone.accountPhoneID,
        accountID: destination.accountID,
        countryID: phone.accountPhoneCountryID,
        phoneNumber: phone.accountPhoneNumber,
        countryPhoneAccessCode: phone.accountCountryPhoneAccessCode,
        phoneTypeID: phone.accountPhoneTypeID,
      })),
      listAccountEmail:
        destination.accountEmailList?.map((email) => ({
          email: email.accountEmail,
          accountID: destination.accountID,
          emailID: email.accountEmailID,
          emailTypeId: email.accountEmailTypeID,
        })) ?? [],
      listAuthorizingAccount: [],
      creationDate: '',
      creationUser: '',
    },
    businessUnit: businessUnitMapper(destination.businessUnit),
    location: {
      name: destination.addressName,
      address:
        destination.addressLine1 +
        (destination.addressLine2 !== null
          ? ' ' + destination.addressLine2
          : ''),
      reference: destination.addressReference,
      postalCode: destination.postalCode,
      coordinates: {
        lat: destination.latitude ?? 0,
        lng: destination.longitude ?? 0,
      },
    },
    store: {
      id: destination.businessUnit?.buid.toString(),
      name: destination.businessUnit?.buName,
      code: destination.businessUnit?.buCode,
      location: {
        name: destination.businessUnit?.buAreaName ?? '',
        address: destination.businessUnit?.address ?? '',
        reference: destination.businessUnit?.reference ?? '',
        postalCode: destination.businessUnit?.postalCode ?? '',
        coordinates: {
          lat: !!destination.businessUnit?.latitude
            ? parseFloat(destination.businessUnit?.latitude)
            : 0,
          lng: !!destination.businessUnit?.longitude
            ? parseFloat(destination.businessUnit?.longitude)
            : 0,
        },
      },
      polygonList: [],
    },
    isHomeDelivery: destination.isHomeDelivery,
  };
};

export const GetModuleByUser = async (
  id?: string,
  roleID?: number
): Promise<NavigationItem[]> => {
  const uri = `${REACT_APP_URL_PATH_API}/Users/GetModuleByUser?UserID=${id}&RoleID=${roleID}&applicationCode=${'020'}`;
  try {
    const token = await AuthenticationServices();
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

    const response = await axios.get(uri, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      const responseModule = response.data;
      let modules: NavigationItem[] = responseModule;
      return modules;
    }
  } catch (error) {
    console.log('ha ocurrido un error', error);
  }

  return [];
};

export const getAccounSiteByUser = async (
  id?: string
): Promise<AccountInterface[]> => {
  const uri = `${REACT_APP_URL_PATH_API}/Users/GetAccounSiteByUser?UserID=${id}`;
  try {
    const token = await AuthenticationServices();
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

    const response = await axios.get(uri, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      const responseModule = response.data;
      if (responseModule.model.length === 0) {
        return [];
      }
      let AccounstSite: AccountInterface[] = [];
      responseModule.model.forEach((item: any) =>
        AccounstSite.push({
          id: item.accountID as string,
          accountCode: item.accountSiteCode,
          BuCodeSource: item.buCodeSource,
          cityName: item.cityName,
          cityCode: item.cityCode,
          abbreviationName: '',
          identificationNumber: '',
          accountFullName: item.accountSiteName,
          listAccountPhone: [],
          listAccountEmail: [],
          listAuthorizingAccount: [],
          accountSiteId: item.accountSiteID,
          accountBillToId: item.accountBillTo,
        })
      );
      return AccounstSite;
      //return modules;
    }
  } catch (error) {
    console.log('ha ocurrido un error', error);
  }

  return [];
};

export const GetAccountSiteByUser = async (
  accountID?: string,
  accountSiteId?: number
): Promise<ListResponse<AccountSiteInterfases[]>> => {
  const uri = `${REACT_APP_URL_PATH_API}/account/GetAccountSite?AccountID=${accountID}&AccountSiteId=${accountSiteId}`;
  const token = await AuthenticationServices();
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  var response = await axios
    .get(uri)
    .then(function (response) {
      var responsedto = response.data;
      return responsedto as ListResponse<AccountSiteInterfases[]>;
    })
    .catch(function (error) {
      if (error.response) {
        console.log(error.message ?? '');
        return error.response as ListResponse<AccountSiteInterfases[]>;
      } else {
        console.log(error.message ?? '');
      }
      return [] as unknown as ListResponse<AccountSiteInterfases[]>;
    });

  return response;
};

export const UpdateAccountShipment = async (
  account: AccountShipmentDto
): Promise<ListResponse<AccountShipmentDto>> => {
  const token = await AuthenticationServices();
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  var response = await axios
    .post(`${REACT_APP_URL_PATH_API}/Account/UpdateShipment`, account)
    .then(function (response) {
      var data = response.data as ListResponse<AccountShipmentDto>;
      return data;
    })
    .catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        let errorData = error.response.data as ListResponse<AccountShipmentDto>;
        console.log('Error actualizando cliente:', errorData.errorMessage);
        return errorData;
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error actualizando cliente:', error.message);
      }
      return [] as unknown as ListResponse<AccountShipmentDto>;
    });
  return response;
};

export const GetPaymentMethodAccount = async (
  accountID?: string
): Promise<ListResponse<PaymentMethodAccountDto[]>> => {
  const uri = `${REACT_APP_URL_PATH_API}/account/GetAllPaymentMethodAccount?AccountID=${accountID}`;
  const token = await AuthenticationServices();
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  var response = await axios
    .get(uri)
    .then(function (response) {
      var responsedto = response.data;
      return responsedto as ListResponse<PaymentMethodAccountDto[]>;
    })
    .catch(function (error) {
      if (error.response) {
        console.log(error.message ?? '');
        return error.response as ListResponse<PaymentMethodAccountDto[]>;
      } else {
        console.log(error.message ?? '');
      }
      return [] as unknown as ListResponse<PaymentMethodAccountDto[]>;
    });

  return response;
};
