import { FC, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Icon } from '@iconify/react';
import { Formik, FormikProps } from 'formik';
import { useAppSelector } from '../../store/hooks';
import { getAccountDestinations } from '../../services';
import { UserPlusIcon } from '@heroicons/react/24/outline';
import notebookOutline from '@iconify/icons-mdi/notebook-outline';
import { filterBusinessUnit, filterDestination } from '../../utils/filters';
import {
  AccountInterface,
  DestinationInterface,
  BusinessUnitInterface,
  TaxIdentificationTypeInterface,
} from '../../interfaces';
import {
  Card,
  FormText,
  MainPage,
  UnitEnum,
  PageTitle,
  FormSearch,
  SenderForm,
  FormCheckbox,
  ShipmentForm,
  HorizontalTab,
  AccountDetails,
  DestinationOption,
  BusinessUnitOption,
  DestinationDetails,
  ShipmentFormValues,
  ExpressConsigneeForm,
  ExpressConsigneeFormValues,
} from '../../components';

const CONSIGNEE_TABS = [
  {
    name: 'Libreta',
    icon: <Icon icon={notebookOutline} className="w-5 h-5" />,
  },
  {
    name: 'Express',
    icon: <UserPlusIcon className="w-5 h-5" />,
  },
];
const expressInitialValues = {
  abbreviationName: 'V-',
  identificationNumber: '',
  fullName: '',
  phoneNumber: '',
  belongsTo: undefined as AccountInterface | undefined,
  email: '',
  city: '',
  address: '',
};
const shipmentInitialValues = {
  service: 'CRÉDITO | CARGA',
  tracking: '',
  pieces: '',
  unit: UnitEnum.KG,
  weight: '',
  volume: {
    length: '',
    width: '',
    height: '',
  },
  amountDeclared: '',
  amountDeclaredByInvoice: '',
  isSafeKeeping: false,
  observations: '',
};

interface CreateShipmentFormValues {
  sender: {
    fullName: string;
    abbreviationName: TaxIdentificationTypeInterface;
    identificationNumber: string;
  };
  destination: {
    data: DestinationInterface | undefined;
    attentionTo: string;
    atStore: boolean;
    businessUnit: BusinessUnitInterface | undefined;
  };
  express: ExpressConsigneeFormValues;
  shipment: ShipmentFormValues;
}

interface FormProps {
  formik: FormikProps<CreateShipmentFormValues>;
}
const Form: FC<FormProps> = ({ formik }) => {
  const user = useAppSelector((state) => state.user)!;
  const businessUnits = useAppSelector(
    (state) => state.inmutable.businessUnits
  );

  const [consigneeTab, setConsigneeTab] = useState(0);
  const [destinationSearch, setDestinationSearch] = useState('');
  const [businessUnitSearch, setBusinessUnitSearch] = useState('');
  const [destinations, setDestinations] = useState<DestinationInterface[]>([]);

  const updateDestinationSearch = useCallback(() => {
    if (!!formik.values.destination.data) {
      setDestinationSearch(
        formik.values.destination.data.account.accountCode +
          ' - ' +
          formik.values.destination.data.account.accountFullName
      );
    } else {
      setDestinationSearch('');
    }
  }, [formik.values.destination.data]);

  const updateBusinessUnitSearch = useCallback(() => {
    if (!!formik.values.destination.businessUnit) {
      setBusinessUnitSearch(
        formik.values.destination.businessUnit?.buCode +
          ' - ' +
          formik.values.destination.businessUnit?.location.name
      );
    } else {
      setBusinessUnitSearch('');
    }
  }, [formik.values.destination.businessUnit]);

  useEffect(() => {
    const account = user.client;
    if (!account) return;

    const getDestinations = async () => {
      const response = await getAccountDestinations(account.id);
      setDestinations(response);
    };

    getDestinations();
  }, [user.client]);

  useEffect(() => {
    if (consigneeTab == 1) {
      formik.setFieldValue('destination.data', undefined);
    } else {
      formik.setFieldValue('express', expressInitialValues);
    }

    // Update formik when formik values change will cause an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consigneeTab]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="flex flex-1 flex-col gap-16 w-full"
    >
      <div className="flex flex-1 flex-col xl:flex-row gap-16">
        {/* Shipper */}
        <Card title="Datos del Remitente">
          <div className="flex flex-1 flex-col p-4 gap-12">
            {/* Sender form */}
            <div className="sm:px-8">
              <SenderForm hiddeIdentification formik={formik} />
            </div>

            {/* Shipper details */}
            <div className="flex flex-1 flex-col border rounded px-4 pt-2 pb-6 gap-4 shadow bg-white">
              <div className="flex flex-1 font-semibold text-sm text-gray-700">
                Detalles del Remitente
              </div>

              <hr className="-mt-2" />

              <AccountDetails {...user.client!} />
            </div>
          </div>
        </Card>

        {/* Consignee */}
        <Card title="Datos del Destinatario">
          <div className="px-6">
            <HorizontalTab
              current={consigneeTab}
              tabs={CONSIGNEE_TABS}
              onChange={setConsigneeTab}
            />
          </div>

          <div className="flex flex-1 flex-col py-4 mb-6 px-4 sm:px-6">
            {consigneeTab == 0 && (
              <div>
                <h4 className="text-sm text-main-500 font-bold mb-4">
                  Libreta de Destinatarios:
                </h4>

                <FormSearch
                  id="destination.data"
                  name="destination.data"
                  label="Destinatario"
                  value={destinationSearch}
                  options={destinations}
                  unselect={!!formik.values.destination.data}
                  className="!h-8"
                  labelClassname="!text-xs"
                  labelContainerClassname="flex sm:!justify-end sm:w-32"
                  containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
                  onBlur={formik.handleBlur}
                  onChange={(e) => setDestinationSearch(e.target.value)}
                  onChangeFocus={(focus) => !focus && updateDestinationSearch()}
                  onSelectOption={(option) =>
                    formik.setFieldValue('destination.data', option)
                  }
                  onFilter={filterDestination}
                  style={{ maxWidth: '20rem' }}
                  RenderOption={({ option }) => (
                    <DestinationOption {...option} />
                  )}
                />

                {!!formik.values.destination.data && (
                  <div className="flex flex-1 flex-col border rounded px-4 pt-2 pb-6 gap-4 shadow bg-white mt-6">
                    <div className="flex flex-1 font-semibold text-sm text-gray-700">
                      Detalles del Destinatario
                    </div>

                    <hr className="-mt-2" />

                    <DestinationDetails {...formik.values.destination.data} />
                  </div>
                )}
              </div>
            )}

            {consigneeTab == 1 && (
              <div>
                <h4 className="text-sm text-main-500 font-bold mb-4">
                  Destinatario Express:
                </h4>

                <ExpressConsigneeForm formik={formik} />
              </div>
            )}

            <hr className="my-6" />

            <div className="flex flex-1 flex-col gap-2">
              <FormText
                id="destination.attentionTo"
                name="destination.attentionTo"
                label="Atención a"
                value={formik.values.destination.attentionTo}
                error={
                  formik.touched?.destination?.attentionTo &&
                  formik.errors?.destination?.attentionTo
                    ? formik.errors?.destination?.attentionTo
                    : undefined
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="!h-8"
                labelClassname="!text-xs"
                labelContainerClassname="flex sm:!justify-end sm:w-32"
                containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
                style={{ maxWidth: '15rem' }}
              />

              <FormCheckbox
                id="destination.atStore"
                name="destination.atStore"
                label="Retiro en Tienda"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                checked={formik.values.destination.atStore}
                labelClassname="!text-xs !ml-0"
                labelContainerClassname="flex sm:!justify-end sm:w-32 h-8 items-center"
                containerClassname="justify-end flex flex-1 gap-8 items-center !flex-row-reverse"
              />

              <FormSearch
                id="destination.businessUnit"
                name="destination.businessUnit"
                label="Oficina"
                value={businessUnitSearch}
                options={businessUnits}
                unselect={!!formik.values.destination.businessUnit}
                className="!h-8"
                labelClassname="!text-xs"
                labelContainerClassname="flex sm:!justify-end sm:w-32"
                containerClassname={classNames(
                  'flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row',
                  !formik.values.destination.atStore && 'hidden'
                )}
                error={
                  formik.touched?.destination?.businessUnit &&
                  formik.errors?.destination?.businessUnit
                    ? formik.errors?.destination.businessUnit
                    : undefined
                }
                onBlur={formik.handleBlur}
                onChange={(e) => setBusinessUnitSearch(e.target.value)}
                onChangeFocus={(focus) => !focus && updateBusinessUnitSearch()}
                onSelectOption={(option) =>
                  formik.setFieldValue('destination.businessUnit', option)
                }
                onFilter={filterBusinessUnit}
                style={{ maxWidth: '20rem' }}
                RenderOption={({ option }) => (
                  <BusinessUnitOption {...option} />
                )}
              />
            </div>
          </div>
        </Card>
      </div>

      <div className="flex flex-1 max-w-full">
        {/* Shipment */}
        <Card title="Datos del Envío" className="max-w-full">
          <div className="flex flex-1 flex-col px-4 sm:px-12 py-4 mb-6">
            <ShipmentForm formik={formik} />
          </div>
        </Card>
      </div>
    </form>
  );
};

const CreateShipment: FC = () => {
  const user = useAppSelector((state) => state.user)!;

  const identificationTypes = useAppSelector(
    (state) => state.inmutable.taxIdentificationTypes
  );

  const senderInitialValues = {
    fullName: user.client?.accountFullName ?? '',
    abbreviationName: identificationTypes.find(
      (t) => t.abbreviationName === (user.client?.abbreviationName ?? 'V-')
    )!,
    identificationNumber: user.client?.identificationNumber ?? '',
  };

  return (
    <MainPage>
      <PageTitle title="Generar Envío" />

      <Formik
        initialValues={{
          sender: senderInitialValues,
          destination: {
            attentionTo: '',
            atStore: false,
            data: undefined as DestinationInterface | undefined,
            businessUnit: undefined as BusinessUnitInterface | undefined,
          },
          express: expressInitialValues,
          shipment: shipmentInitialValues,
        }}
        onSubmit={async (values, { resetForm }) => {
          resetForm();
        }}
      >
        {(formik) => <Form formik={formik} />}
      </Formik>
    </MainPage>
  );
};

export default CreateShipment;
